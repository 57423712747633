<template>
  <div class="section_goods">
    <div class="info">
      <div class="left">{{ orderData.orders.mstep }}</div>
      <div class="right">{{ day(orderData.orders.deposit_date) }} 결제</div>
    </div>
    <div class="num"><b>주문번호</b>&nbsp;&nbsp;{{ orderSeq }}</div>
    <div class="goods_num">
      <b>상품 번호 </b>{{ orderData.items[0].goods_seq }}{{ itemSeq
      }}{{ optionSeq }}
    </div>
    <div v-for="(item, index) in itemList" :key="index">
      <div class="goods main" v-if="parseInt(item.rt_ea) > 0">
        <div v-if="isRefund" :style="{ padding: '10px 0', fontSize: '12px' }">
          · 아래에서 반품할 상품에 체크해주세요.
        </div>
        <div v-if="isReturn" :style="{ padding: '10px 0', fontSize: '12px' }">
          · 아래에서 교환할 상품에 체크해주세요.
        </div>
        <div
          class="goods_seq"
          :style="!isRefund && !isReturn ? { paddingTop: '15px' } : ''"
        >
          <label
            ><input
              v-if="isRefund || isReturn || isDeclaration"
              type="checkbox"
              name="item"
              ref="mainCk"
              :data-item-seq="item.item_seq"
              :data-item-option="item.item_option_seq"
              @click="removeItemAdd(item.opt_type, $event)"
              :disabled="isDeclaration && item.declaration"
            />&nbsp;
            <span v-if="isDeclaration && item.declaration" @click="infoMsg"
              >본상품</span
            >
            <span>본상품</span>
          </label>
        </div>
        <div class="img">
          <img :src="item.image" @error="replaceImg" :alt="item.goods_name" />
        </div>
        <div class="txt">
          <h3>{{ item.goods_name }}</h3>
          <!-- <h4>
            {{ won(parseInt(item.sale_price) * parseInt(item.ea)) }}원
            <span
              v-if="
                parseInt(item.sale_price) * parseInt(item.ea) <
                parseInt(item.out_price) * parseInt(item.ea)
              "
            >
              {{ won(parseInt(item.out_price) * parseInt(item.ea)) }}원
            </span>
          </h4> -->
          <h4>
            {{ won(parseInt(item.sale_price)) }}원
            <span v-if="parseInt(item.sale_price) < parseInt(item.out_price)">
              {{ won(parseInt(item.out_price)) }}원
            </span>
          </h4>
          <p class="shipping_txt">(배송비 제외)</p>
          <p>수량 : {{ item.ea }}개</p>
          <p v-if="item.title1">{{ item.title1 }} : {{ item.option1 }}</p>
          <p v-if="item.title2">{{ item.title2 }} : {{ item.option2 }}</p>
          <p v-if="item.title3">{{ item.title3 }} : {{ item.option3 }}</p>
          <p v-if="item.title4">{{ item.title4 }} : {{ item.option4 }}</p>
          <p v-if="item.title5">{{ item.title5 }} : {{ item.option5 }}</p>
        </div>
        <div v-if="isRefund || isReturn" class="main_ea_box">
          <span class="ea_txt">{{ isRefund ? "반품" : "교환" }}수량 :</span>
          <span class="ea_box">
            <router-link to="" @click.native="minus('main')">-</router-link>
            <input
              type="number"
              :value="item.rt_ea"
              min="1"
              :max="item.rt_ea"
              ref="main"
              readonly="readonly"
            /><router-link to="" @click.native="plus('main')"
              >+</router-link
            ></span
          >
          <span class="ea_txt"> 개</span>
        </div>
      </div>
      <template v-for="(sub, index) in item.suboptions">
        <div :key="index" class="goods sub" v-if="parseInt(sub.rt_ea) > 0">
          <div class="suboptions">
            <label
              ><input
                v-if="isRefund || isReturn || isDeclaration"
                type="checkbox"
                name="item"
                :data-item-seq="item.item_seq"
                :data-item-option="item.item_option_seq"
                :data-item-suboption="sub.item_suboption_seq"
                :disabled="
                  (parseInt(sub.individual_return) <= 0 && !isDeclaration) ||
                  (isDeclaration && sub.declaration)
                "
                :checked="
                  parseInt(sub.individual_return) <= 0 && !isDeclaration
                "
                :ref="`subCk_${sub.item_suboption_seq}`"
                @click="removeItemAdd(sub.opt_type, $event)"
              />&nbsp;
              <span
                v-if="parseInt(sub.individual_return) <= 0 && !isDeclaration"
                @click="removeInfo"
                >추가 상품</span
              >

              <span
                v-else-if="isDeclaration && sub.declaration"
                @click="infoMsg"
                >추가 상품</span
              >
            </label>
            <p>
              {{ sub.title }}({{ sub.suboption }})
              <!-- <span>{{ won(parseInt(sub.price) * parseInt(sub.ea)) }}원</span> -->
              <span>{{ won(parseInt(sub.price)) }}원</span>
              {{ sub.ea }}개
            </p>
            <p v-if="isRefund || isReturn">
              <span class="ea_txt">{{ isRefund ? "반품" : "교환" }}수량 :</span>
              <span class="ea_box">
                <router-link
                  to=""
                  @click.native="minus(`sub_${sub.item_suboption_seq}`)"
                  >-</router-link
                >
                <input
                  type="number"
                  :value="sub.rt_ea"
                  min="1"
                  :max="sub.rt_ea"
                  :ref="`sub_${sub.item_suboption_seq}`"
                  :id="`sub_${sub.item_suboption_seq}`"
                  :disabled="parseInt(sub.individual_return) <= 0"
                  readonly="readonly"
                /><router-link
                  to=""
                  @click.native="plus(`sub_${sub.item_suboption_seq}`)"
                  >+</router-link
                ></span
              >
              <span class="ea_txt"> 개</span>
            </p>
          </div>
        </div>
      </template>
      <p v-if="parseInt(item.provider_seq) === 1" class="privder_info">
        <span class="left">{{ item.provider_name }}</span>
        <!-- <span class="right"
          ><router-link :to="`/mypage/order_detail/${orderSeq}`"
            >주문상세 &gt;</router-link
          >
          </span> -->
      </p>
      <p v-else class="privder_info">
        <span class="left"
          >{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
        >
        <!-- <span class="right"
          ><router-link :to="`/mypage/order_detail/${orderSeq}`"
            >주문상세 &gt;</router-link
          ></span
        > -->
      </p>
    </div>
  </div>
</template>

<script>
import _sortBy from "lodash/sortBy";
export default {
  data() {
    return {
      itemListArray: [],
    };
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    orderSeq: {
      typeo: String,
      default: "",
      required: true,
    },
    itemSeq: {
      typeo: String,
      default: "",
      required: true,
    },
    optionSeq: {
      typeo: String,
      default: "",
      required: true,
    },
    isRefund: {
      type: Boolean,
      default: false,
    },
    isReturn: {
      type: Boolean,
      default: false,
    },
    isDeclaration: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    init() {
      this.itemListArray = [];
    },
    day(str) {
      return this.$moment(str).format("YYYY-MM-DD");
    },
    won(str) {
      return this.$won(str);
    },
    infoMsg() {
      this.$toast.default("이미 신고 된 상품 입니다.");
    },
    plus(ref) {
      const refInput = this.$refs[ref][0];
      const value = parseInt(refInput.value);
      const max = parseInt(refInput.attributes.max.value);
      let checkRef = null;
      if (ref.toLowerCase() === "main") {
        checkRef = "mainCk";
      } else {
        checkRef = ref.replace("sub_", "subCk_");
      }

      if (this.$refs[checkRef][0].checked === false) {
        if (value >= max) {
          refInput.value = max;
          if (this.isRefund) {
            this.$toast.default(`최대 반품 가능 수량은 ${max}개 입니다.`);
          } else if (this.isReturn) {
            this.$toast.default(`최대 교환 가능 수량은 ${max}개 입니다.`);
          }
        } else {
          refInput.value++;
        }
      }
    },
    minus(ref) {
      const refInput = this.$refs[ref][0];
      const value = parseInt(refInput.value);
      const min = 1;

      let checkRef = null;
      if (ref.toLowerCase() === "main") {
        checkRef = "mainCk";
      } else {
        checkRef = ref.replace("sub_", "subCk_");
      }
      if (this.$refs[checkRef][0].checked === false) {
        if (value <= min) {
          refInput.value = min;
          if (this.isRefund) {
            this.$toast.default(`최소 반품 가능 수량은 ${min}개 입니다.`);
          } else if (this.isReturn) {
            this.$toast.default(`최소 교환 가능 수량은 ${min}개 입니다.`);
          }
        } else {
          refInput.value--;
        }
      }
    },
    removeInfo() {
      if (this.isRefund) {
        this.$toast.default("이 추가 상품은 개별  반품 신청 할 수 없습니다.");
      } else if (this.isReturn) {
        this.$toast.default("이 추가 상품은 개별  교환  신청 할 수 없습니다.");
      } else {
        console.log("confirm");
      }
    },
    removeItemAdd(type, event) {
      if (type.toLowerCase() === "sub") {
        const optionSeq = event.target.dataset.itemSuboption;
        Object.keys(this.orderData.loop).forEach((key) => {
          this.orderData.loop[key].export_item.forEach((item) => {
            if (
              parseInt(item.item_seq) === parseInt(this.itemSeq) &&
              parseInt(item.item_option_seq) === parseInt(this.optionSeq) &&
              parseInt(item.option_seq) === parseInt(optionSeq)
            ) {
              if (
                parseInt(item.individual_return) <= 0 &&
                !this.isDeclaration
              ) {
                this.removeInfo();
              } else {
                const data = {
                  itemSeq: event.target.dataset.itemSeq,
                  itemOption: event.target.dataset.itemOption,
                  itemSuboption: event.target.dataset.itemSuboption,
                  ea:
                    this.isRefund || this.isReturn
                      ? this.$refs[
                          `sub_${event.target.dataset.itemSuboption}`
                        ][0].value
                      : item.ea,
                };
                this.$emit("removeItemAdd", event.target.checked, data);
              }
            }
          });
        });
      } else {
        Object.keys(this.orderData.loop).forEach((key) => {
          this.orderData.loop[key].export_item.forEach((item) => {
            if (
              parseInt(item.item_seq) === parseInt(this.itemSeq) &&
              parseInt(item.item_option_seq) === parseInt(this.optionSeq) &&
              item.opt_type.toLowerCase() !== "sub"
            ) {
              //개별 취소가 가능하지 않을떄
              if (
                parseInt(item.individual_return) <= 0 &&
                !this.isDeclaration
              ) {
                let dataArray = [];
                const checks = document.querySelectorAll("input[name=item]");
                checks.forEach((input, index) => {
                  dataArray.push({
                    itemSeq: "0",
                    itemOption: "0",
                    itemSuboption:
                      parseInt(index) <= 0 ? "0" : input.dataset.itemSuboption,
                    ea:
                      parseInt(index) <= 0
                        ? this.isRefund || this.isReturn
                          ? this.$refs["main"][0].value
                          : item.ea
                        : this.isRefund || this.isReturn
                        ? this.$refs[`sub_${input.dataset.itemSuboption}`][0]
                            .value
                        : item.ea,
                  });
                });

                this.$emit(
                  "removeItemAdd",
                  event.target.checked,
                  dataArray,
                  "all"
                );
              } else {
                //개별 취소가 가능할 떄
                const data = {
                  itemSeq: event.target.dataset.itemSeq,
                  itemOption: event.target.dataset.itemOption,
                  itemSuboption: "0",
                  ea:
                    this.isRefund || this.isReturn
                      ? this.$refs["main"][0].value
                      : item.ea,
                };
                this.$emit("removeItemAdd", event.target.checked, data);
              }
            }
          });
        });
      }
    },
  },
  computed: {
    itemList() {
      let data = {
        addPrice: 0,
        suboptions: [],
      };
      if (this.orderData.exports) {
        this.orderData.exports.forEach((ex) => {
          if (ex.item) {
            ex.item.forEach((item) => {
              if (
                parseInt(item.item_seq) === parseInt(this.itemSeq) &&
                parseInt(item.item_option_seq) === parseInt(this.optionSeq) &&
                item.opt_type.toLowerCase() !== "sub"
              ) {
                data.provider_seq = ex.shipping_provider_seq;
                data.provider_name = ex.provider_name;
                data.out_price = item.out_price;
                data.sale_price = item.sale_price;
                data.image = item.image;
                data.goods_name = item.goods_name;
                data.goods_seq = item.goods_seq;
                data.item_option_seq = item.item_option_seq;
                data.item_seq = item.item_seq;
                data.title1 = item.title1;
                data.title2 = item.title2;
                data.title3 = item.title3;
                data.title4 = item.title4;
                data.title5 = item.title5;
                data.option1 = item.option1;
                data.option2 = item.option2;
                data.option3 = item.option3;
                data.option4 = item.option4;
                data.option5 = item.option5;
                data.opt_type = item.opt_type;
                data.ea = item.ea ? item.ea : 0;
                data.rt_ea = item.rt_ea ? item.rt_ea : 0;
                data.individual_return = item.individual_return;
                data.declaration = item.declaration;
                return true;
              }
              if (
                parseInt(item.item_seq) === parseInt(this.itemSeq) &&
                parseInt(item.item_option_seq) == parseInt(this.optionSeq) &&
                item.opt_type.toLowerCase() === "sub"
              ) {
                let title =
                  item.title1 ||
                  item.title2 ||
                  item.title3 ||
                  item.title4 ||
                  item.title5;
                let suboption =
                  item.option1 ||
                  item.option2 ||
                  item.option3 ||
                  item.option4 ||
                  item.option5;
                let price = parseInt(item.price);
                data.addPrice += parseInt(item.price);
                data.suboptions.push({
                  title,
                  suboption,
                  ea: item.ea ? item.ea : 0,
                  price,
                  opt_type: item.opt_type,
                  item_suboption_seq: item.option_seq,
                  rt_ea: item.rt_ea ? item.rt_ea : 0,
                  individual_return: item.individual_return,
                  declaration: item.declaration,
                });
              }
            });
          }
        });
        data.suboptions = _sortBy(data.suboptions, "item_suboption_seq");
        this.itemListArray.push(data);
      }
      return this.itemListArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.section_goods {
  .goods {
    border-bottom: 1px solid #cccccc;
    &.main {
      padding-bottom: 15px;
    }
    .goods_seq {
      padding-bottom: 10px;
    }
    .suboptions {
      padding: 10px 0px;
      p {
        font-size: 14px;
        color: #545454;
        font-weight: 600;
        padding: 10px 0 0 0;
        span {
          font-size: 16px;
          color: black;
        }
      }
      .ea_txt {
        font-size: 13px;
        line-height: 18px;
        color: #6d6d6d;
        font-weight: 400;
      }
      input:disabled {
        background-color: #dbdbdb;
      }
    }
    .txt {
      padding-top: 0;
      .shipping_txt {
        font-size: 11px;
        margin-bottom: 10px;
        line-height: 11px;
      }
      h4 {
        margin-bottom: 5px;
      }
      p {
        line-height: 18px;
      }
    }
    .ea_box {
      width: 100%;
      overflow: hidden;
      margin-left: 5px;
      input {
        width: 25px;
        height: 25px;
        border: 1px solid #545454;
        background: #fff;
        text-align: center;
        font-size: 14px;
      }
      a {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        background: #d7d7d7;
        color: #545454;
        font-weight: 600;
      }
    }
    .main_ea_box {
      width: 100%;
      position: relative;
      float: left;
      padding: 10px 0 0 0;
    }
  }
  .privder_info {
    padding: 15px 0 0 0;
    .right {
      float: right;
    }
  }
  .goods_num {
    border-bottom: 1px solid #cccccc;
    padding: 0 0 10px 0;
  }
}
</style>
