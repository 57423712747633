<template>
  <div>
    <div class="radio_sel box1">
      <h4>부담해야 할 배송비</h4>
      <p v-if="isChange">
        교환배송비(편도):<span>{{ isCost ? won(returnCost) : 0 }}</span
        >원
      </p>
      <p v-else>
        반품배송비(편도):<span>{{ isCost ? won(returnCost) : 0 }}</span
        >원
      </p>
      <div>
        <p>
          <b>반품/교환 배송비: 구매자 부담</b>의 경우 반품은 편도 배송비, 교환은
          왕북 배송비(반품+재발송)가 적용됩니다.
        </p>
        <p>
          <b>배송비 무료 상품</b>의 경우 구매자 귀책 사유로 반품/교환이
          진행되면, 반품은 왕복 배송비(발송+반품), 교환은 최초 발송 비용을
          제외한 왕복 배송비(반품+재발송)가 적용됩니다.
        </p>
      </div>
    </div>
    <div class="radio_sel box2" v-if="isCost">
      <div>
        <h4>배송방법 선택</h4>
        <p>(반품/교환 배송비 구매자 부담의 경우만)</p>
      </div>
      <ul>
        <li>
          <label
            ><input
              type="radio"
              name=""
              v-model="returnType"
              value="1"
              @change="selectType"
            />
            입점사가 지정한 택배를 이용할래요.(배송비 선결제)
          </label>
        </li>
        <li v-if="!isChange">
          <label
            ><input
              type="radio"
              name=""
              v-model="returnType"
              value="4"
              @change="selectType"
            />
            입점사가 지정한 택배를 이용할래요.(환불금액에서 차감)
          </label>
        </li>
        <li>
          <label
            ><input
              type="radio"
              name=""
              v-model="returnType"
              value="2"
              @change="selectType"
            />
            입점사가 지정한 택배를 이용할래요.(배송비 박스 동봉)
          </label>
        </li>
        <li>
          <label
            ><input
              type="radio"
              name=""
              v-model="returnType"
              value="3"
              @change="selectType"
            />
            내가 직접 원하는 택배사로 보낼래요.
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    isChange: {
      type: Boolean,
      default: false,
    },
    isCost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      returnType: null,
    };
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg"]),

    returnCost() {
      const resturnCost = Object.keys(this.orderData.loop).map(
        (key) => this.orderData.loop[key]["export_item"][0]
      );

      console.log(
        this.isChange,
        resturnCost[0].refund_shiping_cost,
        resturnCost[0].swap_shiping_cost
      );

      if (this.isChange && this.orderData.loop) {
        return parseInt(resturnCost[0].swap_shiping_cost);
      } else {
        return parseInt(resturnCost[0].refund_shiping_cost);
      }
    },
  },
  methods: {
    won(str) {
      return this.$won(str);
    },
    selectType() {
      this.$emit("selectType", this.returnType, parseInt(this.returnCost));
    },
  },
};
</script>

<style lang="scss" scoped>
.radio_sel {
  &.box1 {
    p {
      line-height: 20px;
      span {
        margin-left: 10px;
        font-weight: 600;
      }
    }
    p:nth-child(1) {
      margin-top: 9px;
    }
    div {
      margin-top: 20px;
    }
  }
  &.box2 {
    div {
      display: inline-block;
      h4 {
        display: inline-block;
      }
      p {
        display: inline-block;
      }
    }
  }
}
</style>
